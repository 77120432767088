body {
  margin: 0;
  padding: 0;
  font-family: 'Cabinet Grotesk';
  font-weight: 400;
  color: #333333;
}
.homebg{
  background: url("./assets/images/fullbg.png") no-repeat;
  background-size: 115% 100%;
  /* background-size: 115%; */
  background-position: top;
}
a {
  color: #00ffcc;
  text-decoration: none;
}

a:hover {
  color: #ff437f;
  text-decoration: underline;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  width: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #9f9b9f;
}

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url("./assets/font/CabinetGrotesk-Regular.woff2") format('woff2'),
    url('./assets/font/CabinetGrotesk-Regular.woff') format('woff'),
    url('./assets/font/CabinetGrotesk-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url('./assets/font/CabinetGrotesk-Medium.woff2') format('woff2'),
    url('./assets/font/CabinetGrotesk-Medium.woff') format('woff'),
    url('./assets/font/CabinetGrotesk-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Cabinet Grotesk';
  src: url('./assets/font/CabinetGrotesk-Bold.woff2') format('woff2'),
    url('./assets/font/CabinetGrotesk-Bold.woff') format('woff'),
    url('./assets/font/CabinetGrotesk-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'ClashDisplay';
  src: url('./assets/font/ClashDisplay-Medium.woff2') format('woff2'),
    url('./assets/font/ClashDisplay-Medium.woff') format('woff'),
    url('./assets/font/ClashDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'ClashDisplay';
  color: #24020a;
}

.head {
  max-width: 50%;
  margin: 0 auto;
}

.h2tag {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 20px;
}

.h3tag {
  font-size: 30px;
  font-weight: 500;
}

.h5tag {
  font-size: 20px;
  font-weight: 700;
}

.para {
  font-size: 18px;
}


.primary_btn {
  background: #ff4485;
  font-size: 20px;
  font-weight: 500;
  border-radius: 30px;
  color: #fff;
  border: 8px solid #ffd0da;
  padding: 7px 15px;
  position: relative;
  overflow: hidden;
}


.primary_btn:before {
  content: "";
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 60px;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.5;
  filter: blur(30px);
  transform: translateX(-100px) skewX(-15deg);
}
.primary_btn:after {
  content: "";
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  width: 30px;
  height: 100%;
  left: 30px;
  top: 0;
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100px) skewX(-15deg);
}
.primary_btn:hover:before {
  transform: translateX(300px) skewX(-15deg);
  opacity: 0.6;
  transition: 0.7s;
}
.primary_btn:hover:after {
  transform: translateX(300px) skewX(-15deg);
  opacity: 1;
  transition: 0.7s;
}
/* .primary_btn:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255,255,255,0.4);
	-webkit-transition: none;
	   -moz-transition: none;
	        transition: none;
  border-radius: 30px;
}
.primary_btn:hover:after {
  width: 120%;
  background-color: rgba(255,255,255,0);
	-webkit-transition: all 0.4s ease-in-out;
	   -moz-transition: all 0.4s ease-in-out;
	        transition: all 0.4s ease-in-out;
} */

.btn_bdr {
  border: 1px solid #432129;
  background: transparent;
  font-size: 18px;
  color: #24020a;
  font-weight: 500;
  padding: 8px 20px;
  border-radius: 30px;
}
section {
  padding: 40px 0;
}








img.brand_logo {
  width: 190px;
}

.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff3f6;
  z-index: 100;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}

.navbar-sticky--on {
  margin-top: 0;
}

.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.main_navbar .navbar-brand {
  color: #24020a;
  font-size: 22px;
  font-weight: 600;
  font-family: 'ClashDisplay';
  text-decoration: none !important;
}

.main_navbar ul {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.main_navbar ul li{
  margin-left: 2px;
}
.main_navbar ul li:nth-last-child(1) {
  margin-left: 10px;
}
.main_navbar ul li a.active {
  border: 1px solid #432129;
  background: transparent;
  font-size: 18px;
  color: #24020a;
  font-weight: 500;
  border-radius: 30px;
}
.main_navbar ul li a {
  color: #333333;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  padding: 10px 15px;
  border: 1px solid transparent;
  cursor: pointer;
}
.main_navbar ul li .dropdown-item:focus,
.main_navbar ul li .dropdown-item:hover {
     color: #5a3b42;
    background-color: #ffcfd9;
}
.main_navbar .navbar-toggler {
  background-color: #ffdeea;
  color: #c9807b;
  box-shadow: none;
} 
.main_navbar .offcanvas.offcanvas-end {
  background: #f9f1f5;
}


/* Connect Wallet */
.primary_modal .modal-content {
  background: linear-gradient(132deg, #ffb8cb, #ffd0da);
}

.primary_modal .modal-header {
  border-bottom: 1px solid #ffafbf;
  padding: 20px 24px 15px;
}

.connect_wallet_button {
  border: 2px solid #ffafbf;
  background-color: transparent;
  color: #000;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
}
.primary_modal .btn-close{
  filter: invert(0%) sepia(2%) saturate(37%) hue-rotate(204deg) brightness(109%) contrast(101%);
}
.connect_wallet_button:hover {
  border-color: #f34984;
}

.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #000;
}

.connect_wallet_button img {
  margin-right: 15px;
}

.connect_wallet_button span {
  color: #000;
  font-size: 22px;
  font-weight: 400;
}

.btn-close {
  width: 32px;
  height: 32px;
  color: #000;
  border: 0;
  /* background: transparent url("../src/assets/images/close_icon.png") no-repeat; */
  opacity: 0.8;
  outline: none;
  box-shadow: none !important;
}

.btn-close:hover {
  opacity: 1;
}

.primary_modal a {
  text-decoration: none;
}



.page_header .header_box .right span {
  /* margin: 0px 7px;
  font-weight: 400; */
  position: relative;
  top: 2px;
}
.centerText {
  position: relative;
}
.centerText h2 {
  position: absolute;
  top: 45%;
  text-align: center;
  font-size: 50px;
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 9999;
  color: #24020a;
}
/* .header_box{
  position: relative;
  filter: blur(4px);
}
.header_box::after{
  content: "";
  background: rgba(255, 255, 255, 0.55);
  width: 100%;
  display: flex;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  border-radius: 30px;
} */



.page_header {
  text-align: center;
  background: url("./assets/images/banbg.png") no-repeat top;
  padding-top: 30px;
}


.page_header h1 {
  color: #24020a;
  font-size: 48px;
  margin-bottom: 20px;
}

.page_header .ban_img {
  margin-right: -90px;
}

.page_header .header_box {
  padding: 30px;
  border: 2px solid #928e8f;
  border-radius: 35px;
  margin: 30px 0;
  padding-bottom: 60px;
  position: relative;
}
.page_header .header_box  .flx_head {
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.page_header .header_box  .right {
  display: flex;
  align-items: center;
  height: fit-content;
}
.page_header .header_box  .right p {
  margin-bottom: 0;
  font-size: 19px;
  font-weight: 500;
}
.page_header .header_box  .right span{
  margin: 0 8px;
  font-weight: 400;
}
.page_header .header_box  .right i{
  font-size: 16px;
  color: #333333;
  cursor: pointer;
}
.page_header .header_box .para {
  font-size: 20px;
  font-weight: 500;
}

.page_header .header_box h3 {
  margin-bottom: 0;
}

.page_header .header_box .right_box {
  padding: 0 50px;
}

.page_header .header_box .right_box .flx {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  margin-top: 20px;
}

.page_header .header_box .right_box .progress-bar {
  border-radius: 10px;
  background: #65cc65;
  font-size: 13px;
  font-weight: 700;
  text-align: end;
  padding-right: 10px;
}

.page_header .header_box .right_box .progress {
  background: #fffcfc;
  border: 1px solid #9f9b9b;
  padding: 8px;
  height: 37px !important;
  border-radius: 25px !important;
}

.page_header .header_box .primary_btn {
  position: absolute;
  left: 0;
  right: 0;
  width: fit-content;
  margin: 0 auto;
  bottom: -27px;
}

.page_header .header_box .input-group {
  padding: 13px;
  margin: 40px 0;
}

.page_header .header_box .form-control {
  border-color: #ffc8db;
  box-shadow: none !important;
  border-radius: 30px;
}

.page_header .header_box .input-group-text {
  border-radius: 0 30px 30px 0;
  background: #ff4485;
  color: #fff;
  border-color: #ff4485;
}

.timer_panel {
  display: flex;
  /* justify-content: center; */
  text-align: center;
  position: relative;
  margin: 0 auto;
  padding: 0 100px;
}

.timer_time {
  font-size: 52px;
  text-align: center;
  font-weight: 500;
  color: #65cc65;
  font-family: 'ClashDisplay';
}

span.timer_label {
  display: block;
  text-align: center;
  font-size: 16px;
  margin-top: -5px;
  font-weight: 500;
}

span.timer_label.sec {
  text-align: left;
  padding: 0 5px;
}

span.timer_dots {
  font-size: 48px;
  color: #65cc65;
  /* line-height: 82px; */
  font-weight: 700;
}

.timer_panel>span {
  margin: 0px 15px 0 0;
}


.timer_panel::before {
  content: "";
  background: #9f9d9d;
  width: 2px;
  height: 100%;
  right: -12px;
  position: absolute;
}



.about_sec {
  background: url("./assets/images/about_bg.png") no-repeat center;
  padding: 120px 0;
}

.why_sec .box {
  border: 1px solid #333333;
  padding: 30px;
  border-radius: 25px;
  width: 100%;
  height: 200px;
  margin: 11px 0;

}
.why_sec .box p span{
    background: #eba9a5;
    padding: 5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #472930;
    font-weight: 600;
    margin-right: 10px;
    margin-bottom: 5px;
}
.why_sec .col-md-6:nth-child(2) {
  margin-top: 60px;
}

.why_sec .col-md-6:nth-child(3) {
  margin-top: -60px;
}

.why_sec .h5tag {
  margin-bottom: 15px;
}



.chart_sec ul {
  list-style-type: none;
  margin-top: 40px;
}

.chart_sec ul li {
  position: relative;
  font-size: 20px;
  font-weight: 700;
  color: #24020a;
  margin: 10px 0;
}

.chart_sec ul li::before {
  content: "";
  position: absolute;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  border: 1px solid #371b20;
  left: -30px;
  top: 4px;
}

.chart_sec ul li:nth-child(1):before {
  background: #64cc64;
}

.chart_sec ul li:nth-child(2):before {
  background: #92da93;
}

.chart_sec ul li:nth-child(3):before {
  background: #abe0aa;
}

.chart_sec ul li:nth-child(4):before {
  background: #daecd9;
}

.roadmap_sec .container {
  position: relative;
}

.roadmap_sec .head {
  position: absolute;
  right: 20%;
  max-width: 40%;
}
.roadmap_sec .roadmap_res{
  display: none;
}
.roadmap_sec .roadmap_res img{
  margin-bottom: 5px;
}
.roadmap_sec .roadmap_res .box p{
  margin-bottom: 0;
}
.roadmap_sec .roadmap_res .box p:nth-last-child(1){
  margin-bottom: 15px;
}
.faq_sec .accordion {
  background: transparent !important;
}

.faq_sec .accordion-item {
  background: transparent !important;
  margin-bottom: 15px;
  border: 2px solid #928e8d;
  border-radius: 30px;
  color: #24020a !important;
  font-size: 18px;
  font-weight: 500;
}

.faq_sec .accordion-button::after {
  margin-top: -4px;
}

.faq_sec .accordion-button:not(.collapsed) {
  color: #24020a !important;
}

.faq_sec .accordion-button:not(.collapsed)::after {
  background: linear-gradient(140deg, #333333 54%, transparent 60%);
  height: 9px;
  width: 9px;
  transform: rotate(131deg);
}

.faq_sec .accordion-button.collapsed::after {
  background: linear-gradient(140deg, #333333 56%, transparent 0%);
  height: 9px;
  width: 9px;
  transform: rotate(222deg);
}

.faq_sec .accordion-button {
  background: transparent !important;
  box-shadow: none !important;
  border: 0 !important;
}

.footer {
  padding-top: 100px;
}

.footer h5 {
  font-size: 22px;
  color: #24020a;
  font-weight: 600;
}

.footer .para {
  font-size: 14px;
  color: #333333;
  font-weight: 500;
}

.footer .head_ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}

.footer .head_ul li {
  width: 33.3%;
  color: #333333;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
}
.footer .head_ul li a{
  color: #333333 !important;
  text-decoration: none;
  cursor: pointer;
}
.footer .footer_panel_blw {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #333333 !important;
  border-top: 1px solid #333333;
  padding-top: 10px;
  margin-top: 15px;
}
.footer .footer_panel_blw ul {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.footer .footer_panel_blw ul li{
  margin-right: 15px;
  position: relative;
}
.footer .footer_panel_blw ul li::before{
  content: "";
  position: absolute;
  right: -8px;
  height: 80%;
  width: 1px;
  background: #8b8b8b;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.footer .footer_panel_blw ul li:nth-last-child(1):before{
  display: none;
}
.footer .footer_panel_blw a {
  color: #333333 !important;
  text-decoration: none;
}

.footer .social_links {
  display: flex;
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
}

.footer .social_links a {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #d7827d;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fad9d8;
  text-decoration: none;
}

.footer .social_links a:hover {
  background: #24020a;
}

.footer .input-group.mb-3 {
  background: #fff;
  border-radius: 30px;
  padding: 10px;
}

.footer input,
.footer .input-group-text {
  background: transparent;
  border: 0;
  box-shadow: none !important;
  color: #747474;
}


/* star  animation */
.star_img{
  position: relative;
}
.blinking-star {
  -webkit-animation: blink-animation 1s infinite;
  animation: blink-animation 1s infinite;
  background: url("./assets/images/stars.png") no-repeat center;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}

@-webkit-keyframes blink-animation {
  0%   { opacity: 0; }
  50%  { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes blink-animation {
  0%   { opacity: 0; }
  50%  { opacity: 1; }
  100% { opacity: 0; }
}

.blinking-star-about{
  background: url("./assets/images/about_stars.png") no-repeat center;
}
.blinking-star-why{
  background: url("./assets/images/why_stars.png") no-repeat center;
  background-position: 50% 25px;
}
.blinking-star-roadmap{
  background: url("./assets/images/roadmap_stars.png") no-repeat center;
  background-position: 0 0;
}
.blinking-star-faq{
  background: url("./assets/images/why_stars.png") no-repeat center;
  background-position: top;
  background-size: contain;
}
.rdt_Table{
  color: #000;
}
.rdt_TableCol{
  color: #24020a;
  font-size: 16px;
}
.table_sec{
  padding-top: 60px;
}
.rdt_TableHeadRow{
  background: #f7e4e9 !important;
  border-color: #fffcfc !important;
}
.rdt_TableRow{
  background: #fff6f7 !important;
}

.token{
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
}

/* media queries  */
@media (max-width:1199px) {
  .timer_panel {
    padding: 0;
  }

  /* .why_sec .col-md-6:nth-child(3) {
    margin-top: -30px;
  } */
  .why_sec .box{
    height: 250px;
  }
  .blinking-star-about{
    background-size: contain;
  }
  .blinking-star-roadmap{
    background-size: 410px;
  }
}

@media (max-width:991px) {
  .head {
    max-width: 100%;
  }
  .token{
    position: unset;
  }

  .main_navbar ul {
    flex-direction: column;
  }

  .main_navbar ul li {
    margin: 10px 0;
  }

  .timer_panel {
    padding: 0 120px 30px;
  }

  .timer_panel::before {
    display: none;
  }

  .page_header h1 {
    font-size: 42px;
  }
  .page_header .header_box .input-group {
    margin: 0;
   }
  .roadmap_sec .head {
    position: unset;
    max-width: 100%;
  }

  .footer .head_ul {
    padding: 0;
  }
  .blinking-star-why{
    background-size: contain;
  }
  .blinking-star-roadmap {
    background-size: 270px;
}
.why_sec .box{
  height: 280px;
  padding: 20px;
}
.page_header .header_box .flx_head{
  flex-direction: column;
  align-items: center;
  text-align: center;
}
}

@media (max-width:767px) {
  span.timer_dots {
    font-size: 38px;
  }

  .timer_time {
    font-size: 42px;
  }

  .timer_panel {
    padding: 0 50px 30px;
  }

  .page_header .header_box .input-group {
    margin: 0;
  }
  .page_header .header_box .flx_head{
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .why_sec .box{
    height: fit-content;
  }
  .why_sec .box p{ 
    margin-bottom: 0;
  }
  .why_sec .box p span{
    display: none;
  }
  .why_sec .col-md-6:nth-child(2) {
    margin-top: 0;
  }

  .why_sec .col-md-6:nth-child(3) {
    margin-top: 0;
  }

  .footer .footer_panel_blw {
    flex-direction: column;
    align-items: center;
  }

  .faq_sec img {
    margin-top: 30px;
  }

  .chart_sec img {
    margin-bottom: 30px;
  }

  .footer .footer_panel_blw p {
    margin-bottom: 5px;
    text-align: center;
  }

  section {
    padding: 30px 0;
    text-align: center;
  }
  .about_sec {
    padding: 55px 0;
  }
  .chart_sec ul {
    text-align: left;
    width: fit-content;
    margin: 30px auto;
  }
  .footer {
    padding-top: 80px;
    text-align: center;
}
.footer .head_ul {
  flex-direction: column;
  align-items: center;
}
.footer .social_links{
  justify-content: center;
}
  .roadmap_sec .roadmap_res{
    display: block;
  }
  .roadmap_sec .roadmap_img{
    display: none;
  }
  .blinking-star{
    background-size: contain;
  }
  .page_header .header_box .right{
    flex-direction: column;
  }
}

@media (max-width:575px) {
  .page_header .ban_img {
    margin-right: 0px;
  }

  .timer_panel {
    padding: 0 10px 30px;
    justify-content: center;
  }

  .page_header .header_box .right_box {
    padding: 0 0px;
  }
  .page_header .header_box .right{
    flex-wrap: wrap;
    justify-content: center;
  }
  .timer_panel>span {
    margin: 0px 5px 0 0;
  }

  span.timer_dots {
    font-size: 33px;
  }

  .timer_time {
    font-size: 36px;
  }

  .page_header .header_box {
    padding: 20px;
  }

  .page_header h1 {
    font-size: 32px;
  }

  .footer .head_ul {
    padding: 0;
    flex-direction: column;
  }

  .h2tag {
    font-size: 28px;
  }

  .footer .social_links {
    justify-content: center;
  }
  .homebg {
    background-size: cover;
  }
  .footer .footer_panel_blw ul {
    flex-direction: column;
  }
  .footer .footer_panel_blw ul li{
    margin: 0 0 3px;
  }
  .footer .footer_panel_blw ul li::before{
    display: none;
  }
}